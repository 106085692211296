import { Box, Button } from '@material-ui/core'
import Input from '../../share/inputs/Input';
import React, { useState } from 'react'

const CreatePromo = ({ createPromoCode }) => {

    const [name, setName] = useState({
        codePromo: ''
    })
    const { codePromo } = name

    const [validate, setValidate] = useState(false)

    const promo = (e) => {
        e.preventDefault()
        if(codePromo === ''){
            setValidate(true)
        }else{
            createPromoCode(codePromo)
            setValidate(false)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        setName({
            ...name,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-around" width="1">
            <Input
                id="codePromo"
                placeholder=""
                label="Pourcentage du code promotionnel"
                size="small"
                width={'100%'}
                defaultValue={codePromo}
                onBlur={e => handleChange(e)}
                variant="outlined"
                error={(codePromo === '' && validate)  ? 'Requerido' : ''}
            />
 
            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={e => promo(e)}>CREER</Button>
            </Box>

        </Box>
    )
}

export default CreatePromo