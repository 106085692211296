import { Container, makeStyles } from '@material-ui/core'
import DashboardLayout  from '../layout/Layout'
import React, { useState, useEffect } from 'react'
import PaperLayout from '../share/layout/PaperLayout'
import { Box, Button, Divider, Typography } from '@material-ui/core'
import { createPromoAction, deletePromoAction, getPromosAction } from '../../store/actions/promoCodeAction'
import { useDispatch, useSelector } from 'react-redux'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Modal from '@material-ui/core/Modal';
import { Link } from 'react-router-dom';
import CreatePromo from './promocode/CreatePromo'

const useStyle = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 'bold',
        marginRight: '20px',
        fontSize: "1.8rem"
    },
    gridSize: {
        height: '80%',
        width: '100%'
    },
    modalPaper: {
      position: 'absolute',
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }

}))

const PromoCode = () => {
    const classes = useStyle()

    const { auth:{ access_token }, listPromo } = useSelector(
        state => ({ 
            auth: state.auth, 
            listPromo: state.promo.listPromos
        })
    )
    const dispatch = useDispatch()


    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();
    
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
    }
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const [id, setId] = React.useState(null);

    useEffect(()=>{
        dispatch(getPromosAction(access_token))
    },[dispatch, access_token])    

    const createPromoCode = (value) => {
        dispatch(createPromoAction(access_token, value))
    }

    const handleClose = () => {
        setOpen(false);
    };

    const apiRun = (e) => {
        e.preventDefault();
        dispatch(deletePromoAction(access_token, id))
        setId('');
        handleClose();
    }

    const deleteRegister = (e, id, promo) => {
        e.preventDefault();
         setId(id);
         setOpen(true);
    };

    const body = (
        <div style={modalStyle} className={classes.modalPaper}>
          <h2 style={{ fontSize: "22px", marginBottom: '30px' }} >Voulez-vous supprimer le promo code? </h2>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.deleteBtn}
              onClick={e => apiRun(e)}
            >
              Oui
            </Button>
    
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.deleteBtn}
              onClick={handleClose}
            >
              Non
            </Button>
          </Box>
        </div>
    );
    
    return (
        <DashboardLayout>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
            <Container maxWidth="md">
                <PaperLayout>
                    <Typography component="h1" variant="h6" noWrap className={classes.title}>PROMO CODE</Typography>
                    <Box mt={3} mb={3}>
                        
                        <CreatePromo createPromoCode={createPromoCode} />

                    </Box>
              
                    <Typography component="h1" variant="h6" noWrap className={classes.title}>
                        List des promo code
                    </Typography>

                    <Divider className={classes.divider} />
              
                    <Box className={classes.gridSize}>
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row" pb={2} pt={2}>
                                <Box width="50%">CODE</Box>
                                <Box width="50%">POURCENTAGE</Box>
                            </Box>
                            {
                                listPromo.map(promo => {
                                    return(
                                        <Box display="flex" flexDirection="row">
                                            <Box width="50%" style={{color: '#2880fb'}}>{promo.code}</Box>
                                            <Box width="50%">{`${promo.value}%`}</Box>
                                            <Link to="#" onClick={(e) => deleteRegister(e, promo.id, promo.code)} >
                                                <HighlightOffIcon style={{ color: 'red', fontSize: '4.8rem' }} />
                                            </Link>

                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
              
                </PaperLayout>

            </Container>
        </DashboardLayout>
    )
}

export default PromoCode
